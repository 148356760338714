@import "../../common/StyleVariables.scss";

.contact {
    @include mainContentStyle;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    @include mobile {
        flex-direction: column;
    }

    h1 {
        font-weight: 400;
        text-transform: uppercase;
    }
}