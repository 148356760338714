@import "../../common/StyleVariables.scss";

.home {
    @include mainContentStyle;
    flex-grow: inherit;

    h1, p {
        margin-bottom: $s-padding;
    }

    .main-image {
        margin-top: $s-padding;

        img {
            width: 100%;
            max-width: 500px;
        }
    }
}