@import "./common/StyleVariables.scss";

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
}

h1, h2, h3, div, p, a {
  letter-spacing: 2px;
  margin: 0;
}

a {
  color: $text-color;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
    color: $active-link-color;
  }
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}
