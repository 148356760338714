@import './common/StyleVariables.scss';

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .nav-bar {
    margin-top: $l-padding;
    margin-bottom: $l-padding;

    a {
      padding: $s-padding;
      font-weight: $semi-bold;

      &.active {
        color: $active-link-color;
      }
    }
  }
}

