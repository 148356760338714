$s-padding: .3rem;
$l-padding: $s-padding * 4;

$normal: 400;
$semi-bold: 600;
$bold: 800;

$text-color: #2c3e50;
$active-link-color: #42b983;

$standard-icon-size: 25px;

@mixin mobile {
    @media (max-width: 600px) {
        @content
    }
}

@mixin mainContentStyle {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    flex-grow: 1;   
}