@import "../../common/StyleVariables.scss";

.apps {
    @include mainContentStyle;

    .project {
        margin: $l-padding;

        .description {
            margin-top: $s-padding;
            margin-bottom: $l-padding;
        }

        .icon {
            margin-right: $l-padding;
        }
    }

    .green-text {
        color: $active-link-color;
    }
}